<template>
  <section :class="['section', $style.subs]">
    <div :class="['container', $style.wrapper]">
      <div :class="$style.content">
        <h2 :class="['h1', $style.title]" data-aos="fade-right" data-aos-delay="0">
          {{ t('subscribe.title') }}
        </h2>
        <div :class="$style.caption" data-aos="fade-right" data-aos-delay="200">
          {{ t('subscribe.text') }}
        </div>

        <!-- <form
          @submit="onSubmit"
          :class="$style.form"
           data-aos="fade-right" data-aos-delay="400"
        >
          <UiField
            name="email"
            placeholder="Email"
          />
          <UiButton>
            <template #label>
              {{ t('subscribe.button') }}
            </template>
            <template #preppendIcon>
              <UiIconsArrowTopRight />
            </template>
          </UiButton>
        </form> -->

        <div :class="$style.social"  data-aos="fade-right" data-aos-delay="600">
          {{ t('subscribe.follow') }}

          <div :class="$style.socialList">
            <a href="https://t.me/zerodetect" @click="tracking" target="_blank">
              <UiIconsTg />
            </a>
            <a v-if="false" href="https://youtube.com/@aqumbrowser" @click="tracking" target="_blank">
              <UiIconsYouTube />
            </a>
            <a href="https://www.instagram.com/0detect" @click="tracking" target="_blank">
              <UiIconsInsta />
            </a>
          </div>
        </div>
      </div>
      <div :class="$style.banner"  data-aos="fade-left" data-aos-delay="200">
        <img
          v-for="({ image }, index) of slides"
          :key="index"
          :src="image"
          alt=""
          :class="activeSlide === index ? $style.active : null"
        />

        <div :class="$style.nav">
          <div :class="$style.navLabel">{{ slides[activeSlide].label }}</div>
          <UiIconsPlus />
          <div :class="$style.navList">
            <div
              v-for="({ image }, index) of slides"
              :key="index"
              :class="[$style.navItem, activeSlide === index ? $style.active : null]"
              @click.prevent="changeActiveSlide(index)"
            >
              <img :src="image" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useForm } from 'vee-validate';
import * as yup from 'yup';

interface SlideParams {
  image: string,
  label: string,
}

const { t } = useI18n();

const slides = computed<SlideParams[]>(() => [
  {
    image: '/images/subs/subs1.webp',
    label: t('subscribe.slider.1'),
  }, {
    image: '/images/subs/subs2.webp',
    label: t('subscribe.slider.2'),
  }, {
    image: '/images/subs/subs3.webp',
    label: t('subscribe.slider.3'),
  },
]);
const activeSlide = ref<number>(0);
const changeActiveSlide = (index: number) => activeSlide.value = index;

const schema: yup.AnyObject = yup.object({
  email: yup.string().email(t('errors.schema.email.invalid')).required(t('errors.schema.email.req')),
});
const loader = ref<boolean>(false);
const { handleSubmit } = useForm({
  validationSchema: schema,
});

const onSubmit = handleSubmit((value) => {
  // TODO #subscrb for newsletter
});

const tracking = () => {
  fbq('track', 'Search');
}
</script>

<style lang="scss" module>
  .subs {
    border-radius: 24px 24px 0px 0px;
    overflow: hidden;
    background: #fff;
    padding-bottom: 32px;
  }

  .social {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    height: 50px;
    padding: 6px 8px 6px 29px;
    border-radius: 25px;
    border: 1px solid #F0F0F0;
    // margin-top: auto;

    @media (max-width: 767px) {
      flex-direction: column;
      padding: 15px;
      height: auto;
      border-radius: 100px;
      gap: 15px;
    }

    &List {
      display: flex;
      gap: 10px;

      a {
        display: flex;
        color: #121E29;
        text-decoration: none;
        transition: 200ms ease;

        &:hover {
          color: #293EFF;
        }
      }

      svg {
        flex: 1;
        height: 34px;
        width: 34px;
      }
    }
  }

  .wrapper {
    display: flex;
    gap: 80px;

    @media (max-width: 1023px) {
      flex-direction: column;
      gap: 0;
    }
  }

  .content,
  .banner {
    flex: 1;
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 36px 0;
  }

  h2.title {
    line-height: 86px;
    margin-bottom: 24px;

    @media (max-width: 1023px) {
      font-size: 40px;
      line-height: normal;

      br {
        display: none;
      }
    }
  }

  .caption {
    margin-bottom: 34px;
    font-family: 'Gilroy';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
  }

  .form {
    display: flex;
    margin-bottom: 34px;

    > *:nth-child(1) {
      flex: 3;
    }

    label {
      margin-bottom: 0 !important;
    }

    @media (max-width: 767px) {
      flex-direction: column;
      gap: 22px;
    }
  }

  .banner {
    position: relative;
    height: 636px;
    border-radius: 24px;
    overflow: hidden;

    @media (max-width: 1023px) {
      width: 100%;
      flex: unset;
    }

    @media (max-width: 767px) {
      height: 336px;
    }

    > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      transition: 500ms ease;

      &:not(.active) {
        opacity: 0;
      }
    }
  }

  .nav {
    z-index: 2;
    position: absolute;
    bottom: 36px;
    right: 36px;
    display: flex;
    align-items: flex-end;
    color: #fff;

    @media (max-width: 767px) {
      bottom: 20px;
      right: 20px;
    }

    &Label {
      font-family: 'Gilroy';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      max-width: 155px;
      margin-right: 8px;

      @media (max-width: 767px) {
        font-size: 12px;
        max-width: 100px;
      }
    }

    &List {
      display: flex;
      align-items: center;
      gap: 4px;
      height: 69px;
      border-radius: 34.5px;
      padding: 4px 14px;
      background: #fff;

      @media (max-width: 767px) {
        padding: 2px 4px;
        height: 38px;
        gap: 2px;
      }
    }

    &Item {
      flex: 1;
      height: 61px;
      width: 61px;
      padding: 5px;
      border-radius: 30.5px;
      border: 1px solid transparent;
      background: #fff;
      transition: 200ms ease;
      cursor: pointer;

      @media (max-width: 767px) {
        width: 33px;
        height: 33px;
        padding: 3px;
        border-radius: 33px;
      }

      &.active {
        border-color: #121E29;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    svg {
      flex-shrink: 0;
      width: 30px;
      height: 30px;
      margin-bottom: auto;
      margin-right: 14px;

      @media (max-width: 767px) {
        width: 16px;
        height: 16px;
        margin-right: 10px;
      }
    }
  }
</style>